import React from "react"


import openailogo from "../../images/powered-by-openai-badge-outlined-on-dark.svg"
import reactlogo from "../../images/react-logo.svg"

export default function Notes() {
    
    return (
 
        
 
        <section className="homepage--notes">
        
            <div>
            
                <h3 className="homepage--notes-header">* Subject to usage quotas and availability</h3>
                <h3 className="homepage--notes-header">** Usage is subject to Terms & Conditions, User Agreement and Data Policy</h3>

                <div className="homepage--attributions">

                    <img className="poweredby--openai" src={openailogo}/>
                    <img className="poweredby--react" src={reactlogo}/>

                    <img className="poweredby--aws" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"/>

                </div>
                
            </div>
            
            
        </section>
        

    )
    
}