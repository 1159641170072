
import React, { useContext } from "react";
import {NavLink} from "react-router-dom";

import { AuthContext } from "../context/AuthContext";


import nav_logo from "../images/nav-logo.svg";

import imageUser from "../images/imageUser.png";

import logoutButtonWhite from "../images/logout-btn-white.svg"
import logoutButtonRed from "../images/logout-btn-red.svg"

// Renders Navigation bar based on authentication status.

export default function Navbar() {
    
    const { user, signOut } = useContext(AuthContext)

    return (
        
          <div>
            
            <nav className = "nav">  
        
       
                <NavLink to="/">
                    <img 
                        src={nav_logo}
                        alt="service logo"
                        className="nav--icon"
                    />
                </NavLink>

            
            
            <ul className="nav--menu">
                
                {user === null && 
                    <li>
                        <NavLink 
                            to="/about"
                            className={({isActive}) => isActive ? "link--current" : null}
                            >
                            About
                        </NavLink>
                    </li>
                }

                {user &&
                   <li><NavLink to="/practice" className={({isActive}) => isActive ? "link--current" : null}>Practice</NavLink></li>
                }


                {user &&
                    <li><NavLink to="/vocabulary" className={({isActive}) => isActive ? "link--current" : null}>Vocabulary</NavLink></li>
                }

                {user &&
                    <li><NavLink to="/review" className={({isActive}) => isActive ? "link--current" : null}>Review</NavLink></li>
                }

                {user === null &&
                    <li><NavLink to="/login" className={({isActive}) => isActive ? "link--current" : null}>Login</NavLink></li>

                }

                {user === null &&
                    <li><NavLink to="/signup" className={({isActive}) => isActive ? "link--current" : null}>Sign Up</NavLink></li>

                }


               {user &&
                
                <li>
                <NavLink to="/account">
                    <img 
                        src={imageUser}
                        className="account--logo"
                    />
                </NavLink>

                </li>
        
               }    

                

                
                {user &&
                    <li><button className="navbar--button" onClick={signOut}><img 
                    src={logoutButtonWhite}
                    className="account--logo"
                /></button></li>
                }

    


            </ul>

            </nav>

          </div>
        
    )
    
}
