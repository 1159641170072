import React from "react"
import { Link } from "react-router-dom"

import "../../pages/Home/home.css"


export default function ModelIntro() {
    
    return (
 
        
        <section className="homepage--banner">
        
            <div>
                <h1 className="main--header">Put your Mandarin to practice through realistic scenarios.</h1>
                
                <h2 className="main--subheader">Pick a Scenario. Complete Tasks in Chinese.</h2>

                <h2 className="main--subheader">Developed in collaboration with certified tutors.</h2>


            </div>
            
            <div>
                <h2 className="main--subheader">Are you tired of repetitive textbook dialogues that don't quite prepare you for real-life conversations?</h2>
                <h2 className="main--subheader">We are here to revolutionize your Mandarin learning experience.</h2>
                <h2 className="main--subheader">We help you to bridge the gap between theoretical knowledge and practical application.</h2>



            </div>
            
            <div>
                <h1 className="main--header">Image Demo or Carousel Here</h1>
                <h2 className="main--subheader"><Link to="/signup">Try now!</Link></h2>
            </div>
            
            

            
        </section>
        

    )
    
}