import React, { useEffect } from "react"

import "./css/ExistingChats.css"

// Defines, fills and returns a list of existing conversations

import { useContext } from "react"

import { ChatContext } from "../../context/ChatContext"

export default function UserTaskText(){
    
const {currentTask ,taskDetails} = useContext(ChatContext)

    
    if(taskDetails) {



        const taskGuide = taskDetails.map(item => {


            return(
    
                <p>{item}<br/></p>
    
            )
    
    
        })
    
    
    
        return(
            
            <div className="user-task">
    
                <h1><bold>Task Details:</bold></h1>
                
                {taskGuide}
            
            </div>
                
            
        )





    }







    
}