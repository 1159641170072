import React from "react"

import "./css/ExistingChats.css"

import { useContext } from "react"

import { ChatContext } from "../../context/ChatContext"

export default function ExistingChats(){


    const {taskImageLink} = useContext(ChatContext)

    
    return(
        
        <div className="chat-list">
            

            <img src={`${taskImageLink}`} alt="Activity Image Here"/>

        
        </div>
            
        
    )
    
}