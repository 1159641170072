import React, {useEffect} from "react"
import {Link} from "react-router-dom"

import SubCard from "../../components/Scenarios/SubCard"

import { UserContext } from "../../context/UserContext"


import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }

export default function SubScenarioGallery() {

    const {userSessionObject, updateSessionObject, activityData, userConv} = React.useContext(UserContext)


    const [subCards, setSubCards] = React.useState(null)



    useEffect(() => {

        if(activityData && userConv){

            const isCompleted = userConv.Items.filter(arrayItem => (arrayItem.scenarioID.N == userSessionObject.currentScenario))

            log.debug("ActivityData in SSG: ", activityData.scenario_data[0].scID.N == userSessionObject.currentScenario)

            // Filters by Current Scenario

            const filtered_cards = activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0]

            log.debug("Filtered scenario card Object: ", filtered_cards)


            // Feeds subscenario objects to a list


            const cardsBySS = filtered_cards.bySS.L.map(item => {

                return item.M

            })

            log.debug("Filtered subscenario objects in a List: ", cardsBySS)



            // Subscenarios sorted in order by ID


            const subCardsList = cardsBySS.sort((a, b) => (a.ssID.N > b.ssID.N)).map(item => {

                log.debug("Subscenario Card entry looks like:", item)

                const isSubCompleted = isCompleted.filter(arrayItem => (arrayItem.subscenarioID.N === item.ssID.N))
                
                var completedTag

                if (isSubCompleted[0] != null) {
                    completedTag = isSubCompleted[0].isCompleted.BOOL

                }
                else {
                    completedTag = false
                }


                return (


                    <Link to={`/chats`}>

                        <SubCard

                            id={item.ssID.N}
                            item={item}
                            key={item.ssID.N}
                            completedTag={completedTag}
                            
                        />

                    </Link>

                )

            })



            setSubCards(subCardsList)


        } 
        
    }, [activityData, userSessionObject])




    
    return (
    
                
        <section className="cards-list">
        
            {subCards}
            
        </section>

    )
    
}