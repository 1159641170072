import React, { useEffect, useContext } from "react";

import "./css/ChatsPage.css";

import { UserContext } from "../../context/UserContext";
import { ChatContext } from "../../context/ChatContext";

import LaunchActivity from "../../components/Chat/LaunchActivity";
import ChatShell from "../../components/Chat/ChatShell";

import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }


// Activity data, connection and chat logic management
export default function ActivityPage() {


    // Imported UserContext states

    const {chatMessages, scenarioScreenNameEN, subScenarioScreenNameEN, setWebSocketReady, routeInboundEvent, setSocket, webSocketReady, sendMessage, sendInitMessage, Event} = useContext(ChatContext)

    const {user, userSessionObject, setServerMessage} = useContext(UserContext)


    log.debug("Chat messages loaded for conversation: ", chatMessages)

    // TODO: FIX CASE WHEN INIT MESSAGE IS SENT BEFORE CONNECTION TO SOCKET IS ESTABLISHED!!!

    
    useEffect(() => {

        log.debug("connecting to backend...")

        const newSocket = new WebSocket('ws:localhost:8080/ws');

        if(newSocket.OPEN){

            log.debug("Socket connection is open!")


            // Confirms Socket connection is open
            newSocket.addEventListener("open", event => {

                log.debug("Websocket, added event listener")

                setWebSocketReady(true);
                newSocket.send(JSON.stringify(new Event("handshakeMessage", {"scenarioID": userSessionObject.currentScenario, "subscenarioID": userSessionObject.currentSubScenario, "scenario": scenarioScreenNameEN, "subscenario": subScenarioScreenNameEN, "message": "hello", "origin": user.sub })));

            });



            // Listens for Messages
            newSocket.addEventListener("message", (event) => {
                // routeInboundEvent(JSON.parse(event.data))
                setServerMessage(JSON.parse(event.data))
            });


            // Attempts to reconnect if connection is closed
            newSocket.addEventListener("close", (event) => {

                setWebSocketReady(false);

                log.debug("Frontend CloseEvent: Connection Closed.")

                // Reconnects if socket inaccessible or closed
                // setTimeout(() => {

                //     setSocket(new WebSocket("ws://ec2-3-101-43-45.us-west-1.compute.amazonaws.com:8080/ws"));

                // }, 1000);
                
            });        


            newSocket.addEventListener("error", (err) => {

                log.debug("Frontend: Socket connection ERROR ", err.message, ' Closing Connection...')

                setWebSocketReady(false);
                // socket.close();

            });

            setSocket(newSocket);



            return () => {
                log.debug("Component Unmounted: Socket connection Closed.")

                sendMessage("client: activity component unmounted. disconnecting...")

                newSocket.close();
            };


        }

        setWebSocketReady(false);


    }, [])




    return (
    
        <section className="chats--page">
            

        {webSocketReady && <p>WebSocket Connection READY</p>}

        {(chatMessages == null) ? (<LaunchActivity sendMessage={sendInitMessage}/>) : 
                    
                    (
                        
                        <>{(webSocketReady) ? (<ChatShell/>)

                        :

                        (<ChatShell />)}</>

                    )
                    
                    }



                <div className="spaceFiller"></div>


                </section>
                
                

            );
            
        }
