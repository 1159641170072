import React, { useContext, useState } from "react"
// import { signIn } from "../../authentication/authHelper"
// import { redirect, useNavigate } from "react-router-dom"

import { AuthContext } from "../../context/AuthContext"
import { Navigate } from "react-router-dom"

import "./css/style.css"


import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }

export default function LoginPage() {


  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")


  const { user, signIn } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await signIn(username, password)
      // Redirect to the app's main page or dashboard
      log.debug("Sign In Successful")
      // navigate("/scenarios")
    } catch (err) {
      setError(err.message)
    }
  }


  if (user) {
    return <Navigate to="/practice"></Navigate>
  }




  return (

        <div className="login--section">
          <h2>Login</h2>
          <form className="login--form" onSubmit={handleSubmit}>
            <input className="login--form--input"
              type="text"
              placeholder="Username / email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input className="login--form--input"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="login--form--button" type="submit">Login</button>
          

            <a href id="LoginWithAmazon">
        <img border="0" alt="Login with Amazon"
            src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
            width="156" height="32" />
    </a>
          
          </form>
          {error && <p>{error}</p>}

        </div>

 )
}


