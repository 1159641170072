import React, { useContext } from 'react'
import { Navigate } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"


// Redirects to HomePage if not authenticated. Renders @route otherwise.
function RouteGuard({ children }) {
  const { user, isLoading } = useContext(AuthContext)

  if (isLoading) {
    return <></>
  }

  if (!user) {
    return <Navigate to="/" />
  }

  return children
}

export default RouteGuard