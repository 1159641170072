
import React  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom"

import "./home.css"


import ModelIntro from "../../components/Home/ModelIntro"
import Notes from '../../components/Home/Notes';


export default function HomePage() {


  return (
  
      <div className="application--container">


        {<ModelIntro />}

        {/* <section>
          <h1>How Does it Work?</h1>
          <p>We offer realistic scenarios that let you put your learning to practice</p>
          <p>Given a task in English, your goal is to complete the scenario in Chinese</p>
          <p>...</p>


          <p>Given a shopping list in English. Purchase the right groceries at a store while communicating only in Chinese.</p>
          <p>Give directions to a lost tourist in Chinese based on a map</p>
          <p>Configure and Purchase a car of your dreams.</p>


          <h1>Up For A Challenge?</h1>
          <h1><Link to="/signup">Start Now</Link></h1>


        </section>

        <section>
          <h1>How Much Does it Cost?</h1>
          <p>Our App is free while it is still in development.</p>
          
        </section>


        <section>
          <h1>Have a Suggestion?</h1>
          <p>Shoot!</p>
        </section> */}



        <section className="homepage--comingsoon">
        
            <div>
                <h1 className="main--header">Coming Soon.</h1>
                <h1 className="main--header">Please check back later.</h1>            


            </div>
            
            
        </section>


        {<Notes />}


      </div>
      
    
  )
  
}

