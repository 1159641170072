import React from "react";
import { Outlet } from "react-router-dom"

import { getCurrentUser, signOut } from '../../authentication/authHelper';

export default function VocabPage() {


    const [user, setUser] = React.useState()

    React.useEffect(() => {

        const fetchUser = async () => {

        try {

            const user = await getCurrentUser()
            setUser(user)

        } catch (err) {
            console.error(err)
        }


        }

        fetchUser()

    }, [])


    
    return (

        <>
    
        
            <h1>My Vocabulary Page</h1>

            <h1>Vocabulary Panel - Word, PinYin, Pronounciation, EN, Usage Examples, Synonyms</h1>        
            <h1>Options - export as ..., to Anki, Generate Example Sheet PDF</h1>
            <h1>Add a frequency chart or a totals chart</h1>

        
        </>

    )
    
}