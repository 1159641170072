import React, { useContext } from "react"


import { UserContext } from "../../context/UserContext";

import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }


export default function SubCard(props){
        

    const { userSessionObject, updateSessionObject } = useContext(UserContext);

    log.debug("Subcard looks like: ", props.item)



    // let user_level = props.item.M
     
    // // User-level CSS Setup
    // let userLevel
    // let userClass
    // if (props.item.user_level === "beginner") {
    //     userLevel="BEGINNER"
    //     userClass="card--badge--level--green"
    // } else if (props.item.user_level === "intermediate") {
    //     userLevel="INTERMEDIATE"
    //     userClass="card--badge--level--yellow"
    // }
    // else if (props.item.user_level === "advanced") {
    //     userLevel="ADVANCED"
    //     userClass="card--badge--level--red"
    // }

    let completeBadge = props.completedTag


    return (
        
        <div className="card"  onClick= {() => {updateSessionObject("currentSubScenario", props.id)}}>
        

            <div>
                <p className="card--name"> {props.item.ssNameZH.S} </p>
            </div>
        

            <img src={`${props.item.imgLink.S}`} className="card--image" alt=""/>

            
            <div className="card--badge">
                { !props.completedTag && <div className="card--badge--level--green">NEW</div>}
                { props.completedTag && <div className="card--badge--level--yellow">COMPLETED</div>}
            </div>

            <div className="card--intro">
                <p>{ props.item.ssIntro.S }</p>

            </div>


    
        </div>
    
    )


}


        