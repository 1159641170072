import React from "react";

import "./css/ChatHeader.css";

import { useContext } from "react";
import { ChatContext } from "../../context/ChatContext";

export default function ChatHeader() {
    
    const {subScenarioScreenName} = useContext(ChatContext)
    
    return (
    
        <div className="chat-header">
    
            <h1>{subScenarioScreenName}</h1>
        
        </div>
    
    
    );
    
}