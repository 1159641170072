import React from "react"

import { Link } from "react-router-dom"


export default function AboutPage() {
    
    return (
 
        <section className="about--page">
        
            <h1>About Page</h1>
        
        
            <div>
                <h2>Who is this for?</h2>
                <p>This app looks to eliminate the conversational barrier for those who are starting to practice their Mandarin Chinese. For example, Introverted learners will benefit from agent-initiated conversations.</p>
            </div>
            
            <div>
                <h2>How are you better than HelloTalk, iTalki and other existing apps ?</h2>
                <p>We are not! Those apps are an excellent way to communicate with REAL people. There also are Social networks that allow you to experience this method of exposure.</p>
                <p>We focus on pushing you towards that first step of initiating, continuing conversations and understanding the flow of the language.</p>
            </div>
            
            
            <div>
                <h2>But teachers are Better!</h2>
                <p>They are! There is nothing to replace real-human language practice, however it is not always available (high-quality tutoring in particular) due to steep costs for some, ....</p>
                <p>Something Else.</p>
            </div>
            
            
        
            <div>
                <h2>Do We use AI?</h2>
                <p>Yes. Our product is powered by AI. Our conversation agents use current versions of ChatGPT, Google, Facebook and Amazon Bedrock models with prompt engineering as well as necesarry enhancements.</p>
            </div>    
            
            <div>
                <h2>How do you use my conversation and account data?</h2>
                <p>Data collected while you are a Free-Tier or PRO-tier user will be used to train models so we can tailor communication on a more personal level with persistent memory, including factual information provided by you in chats. Data may also be used by companies: OpenAI, Google, .... ; It may be lost, sold and/or used by our partners.</p>
                <p>To read on our Data Policy: Data Policy </p>
            </div>    
            
            
            <div>
                <h2>Do you see my conversations?</h2>
                <p>Yes, we scan conversations for any obscene material and policy violations. Please do not overshare your personal details.</p>
                <p>Your account will be permanently banned if found violating our XXXX Policy or XXXX.</p>
                <p>Please refer to our XXXX (AUP, TAC)? as well as Company Xs XXXX for more information.</p>
            </div>    
            
            
            
            <div>
                <h2>Want to Work with Us?</h2>
                <p>If you are a fellow backend/frontend/DevOPS developer, an influencer or a marketing genius, please get in touch via email below:</p>
                    <p>hiring@mcp.com</p>
                <p>Please note: We do not offer paying positions.</p>
            </div>    
            
            <div>
                <h2>Want to Invest or Donate?</h2>
                <p>Please contact us via resources@mcp.com</p>
                <p>We will get back to you as soon as possible.</p>
            </div>    
            
        
        </section>
        
        
        
        

    )
    
}