
import React, { createContext, useContext, useState, useEffect} from "react";

import { Navigate } from "react-router-dom";

import { UserContext } from "./UserContext";

import userLogo from "../images/imageUser.png"

import log from 'loglevel';


const ChatContext = createContext();


if (process.env.NODE_ENV === 'production') {
  log.setLevel('silent'); // Suppress all logs
} else {
  log.setLevel('debug'); // Enable debug-level logging
}

// // Usage
// log.debug('Debug log');
// log.info('Info log');
// log.error('Error log'); // Always logs errors



function ChatProvider( {children} ) {

    // log.debug("CHATPROVIDER: Loaded ChatProvider component on initial or reload")
    log.debug("CHATPROVIDER: Loaded ChatProvider component on initial or reload")

    // User Context information
    const {activityData, userConv, user, userSessionObject, updateSessionObject, setServerMessage} = useContext(UserContext)

    log.debug("CHATPROVIDER: Current Scenario and subscenario are set as: ", userSessionObject.currentScenario, userSessionObject.currentSubScenario)
    log.debug("CHATPROVIDER: Activity Data is: ", activityData)
    log.debug("CHATPROVIDER: userConv is: ", userConv)


    const [currentTask, setCurrentTask] = useState(null)
    const [taskImageLink, setTaskImage] = useState(null)


    const [scenarioScreenName, setScenarioScreenName] = useState(null)
    const [scenarioScreenNameEN, setScenarioScreenNameEN] = useState(null)
    const [subScenarioScreenName, setSubScenarioScreenName] = useState(null)
    const [subScenarioScreenNameEN, setSubScenarioScreenNameEN] = useState(null)


    const [nextSSname, setNextSSname] = useState(null)
    const [nextSCname, setNextSCname] = useState(null)
    const [ssCount, setSsCount] = useState(null)
    const [scCount, setScCount] = useState(null)


    const [chatMessages, setChatMessages] = useState(null)

    const [taskDetails, setTaskDetails] = useState(null)

    const [salesLogo, setSalesLogo] = useState(null)



    // WebSocket
    const [socket, setSocket] = useState(null)
    const [webSocketReady, setWebSocketReady] = useState(false); 

    class Event {
        constructor(type, payload) {
            this.type = type;
            this.payload = payload;
        }

    }


    function getEventType(event) {

        if(event.type === undefined) { 
            log.debug("No TYPE field defined in the event")
            return undefined
        }

        switch (event.type) {
            case "modelMessage":
                log.debug("New regular chat message from language model");
                break;

                
            case "langErrMessage":
                log.debug("User used language different from Mandarin Chinese");
                break;


            case "grammarErrMessage":
                log.debug("Grammar Correction Message from Backend");
                break;


            case "vocabErrMessage":
                log.debug("Vocabulary Correction Message from Backend");
                break;

            case "taskErrMessage":
                log.debug("Task content error");
                break;


            case "taskCompleteMessage":
                log.debug("Task completed");
                break;


            case "handshakeAckMessage":
                log.debug("Handshake acknowledged from backend!");
                break;

            case "userMessage":
                log.debug("User Message processed by BE");
                break;
                

            case "moderationMessage":
                log.debug("Moderations Flag RAISED");
                break;

            case "nextActivityMessage":
                log.debug("Next activity offer Message");
                break;


            case "reInitActivitAckMessage":
                log.debug("Reinitialization event confirmed from Backend");
                break;

            default:
                log.debug("Unsupported Inbound message type");
                return null
        }


    }


    // Routes Outbound events
    function routeOutboundEvent(event) {

        const eventType = getEventType(event)

        if (eventType == undefined) {
            // TODO handle
        }

        else if (eventType === null) {
            // handle
        }
        
        // only accept valid types for outbound messages
        else if (eventType === "userMessage"){
            sendMessage(event.data)
        }

    }


    // Routes Inbound events. Triggers usercontext reload, db sync.
    function routeInboundEvent(event) {

        if (getEventType(event) == undefined) {
            return false;
        } 
        
        setServerMessage(event)
       
    }


    function sendMessage(message) {

        if(message != null) {
            sendEvent("chatMessage",  {"scenarioID": userSessionObject.currentScenario, "subscenarioID": userSessionObject.currentSubScenario,"scenario": scenarioScreenNameEN, "subscenario": subScenarioScreenNameEN, "message": message, "origin": user.sub })
        }


        return false;
    }


    function sendInitMessage(){

        sendEvent("initMessage",  {"scenarioID": userSessionObject.currentScenario, "subscenarioID": userSessionObject.currentSubScenario, "scenario": scenarioScreenNameEN, "subscenario": subScenarioScreenNameEN, "message": "", "origin": user.sub })

        return true;
    }



    function sendReInitMessage(){

        sendEvent("reInitMessage",  {"scenarioID": userSessionObject.currentScenario, "subscenarioID": userSessionObject.currentSubScenario, "scenario": scenarioScreenNameEN, "subscenario": subScenarioScreenNameEN, "message": "", "origin": user.sub })

        return true;

    }



    function sendEvent(eventName, payload) {

        if(socket == null) {
            return false;
        }
        var socketStatus = socket.readyState

        if((socketStatus == socket.CONNECTING) || (socketStatus == socket.CLOSED) || (socketStatus == socket.CLOSING) || socket == null){
            return false;
        }
        else{

            const event = new Event(eventName, payload);

            log.debug("Created Event of type: ", event)
            log.debug("Frontend: Sending an event of type: ", eventName, JSON.stringify(event))

            socket.send(JSON.stringify(event));
        
        }

    }


    // Processes messages of type "regular". Applies vocabulary highlights.
    function resolveHighlights(item) {

        log.debug("RESOLVEHIGHLIGHTS")

    
        // No vocabulary highlights. 
        if (item['M'].message['S'].split("{").length < 2){
            log.debug("RESOLVEHIGHLIGHTS: No vocabulary highlights located in the message structure: ", item['M'].message['S'])

            return <div className="modelMessage"><img src={`${salesLogo}`} alt="Model Avatar"/><p>{item['M'].message['S']}</p></div>
        }

        log.debug("RESOLVEHIGHLIGHTS: Vocabulary highlights located in the message structure: ", item['M'].message['S'])

        // Split message and vocabulary sections
        const message_text = item['M'].message['S'].split("{")[0]
        const vocab = "{" + item['M'].message['S'].split("{")[1]

        const jVocab = JSON.parse(vocab)

        log.debug("RESOLVEHIGHLIGHTS: message text is: ",message_text, "vocabulary json is: ", jVocab)

        // Construct a message with vocabulary highlights
        // const charArray = Array.from(message_text);
        let elements = []
        let lastIndex = 0;

        // Convert highlights to an array of sorted ranges
        // const ranges = Object.keys(jVocab).flatMap((word) => jVocab[word])
        // const sortedRanges = ranges.sort((a, b) => a[0] - b[0])

        const sortedWords = Object.keys(jVocab).sort((a, b) => b.length - a.length)


        log.debug("RESOLVEHIGHLIGHTS: vocabulary sorted by length: ", sortedWords)


        sortedWords.forEach((word) => {

            log.debug("RESOLVEHIGHLIGHTS loop: considering word: ", word)
            
            let startIdx = message_text.indexOf(word, lastIndex)

            log.debug("RESOLVEHIGHLIGHTS loop: searching for first occurence of word ", word, " starting at ", lastIndex, " result is: ", startIdx)

            // Runs while words are being found within the array.
            // indexOf returns -1 when no word found.
            while(startIdx !== -1){

                // 
                if(lastIndex < startIdx) {

                    elements.push(
                        <span key={`text-${lastIndex}`}>
                            {message_text.slice(lastIndex, startIdx)}
                        </span>
                    )
                }

                // 
                elements.push(
                    <span key={`highlight-${startIdx}`} style={{ color: "orange"}}>
                        {message_text.slice(startIdx, startIdx + word.length)}
                    </span>
                );

                // 
                lastIndex = startIdx + word.length
                startIdx = message_text.indexOf(word, lastIndex)

            }
        })


        // Add the remaining non-highlighted part
        if (lastIndex < message_text.length) {
            
            elements.push(<span key={`text-end`}>{message_text.slice(lastIndex)}</span>);
        }

        
        // Returns a complete message with highlights for rendering
        return (
            <div className="modelMessage"><img src={`${salesLogo}`} alt="Model Avatar"/><p>{elements}</p></div>
        )

    }


    // Resolves next action depending on options available for this user.
    // TODO: Incorporate activity level selection
    function resolveLastStep(item){


        log.debug("CHATPROVIDER: entered resolveLastStep")


        // log.debug("Current scenario contains the following subscenario objects:", activityData.scenario_data.filter(item => (item.scID.N == currentScenario))[0].bySS.L)

        log.debug("Current scenario index is: ", userSessionObject.currentScenario)
        log.debug("Current subscenario index is: ", userSessionObject.currentSubScenario)
        log.debug("Total scenarios available: ", scCount)
        log.debug("Total subscenarios available for this scenario: ", ssCount)
        log.debug("Next subscenario is: ", nextSSname)
        log.debug("Next scenario is: ", nextSCname)


        // If another subscenario exists within this scenario, create a link to it
        if((nextSSname !== null)){

            log.debug("Next SubScenario is set as: ", nextSSname)

            return (

                <div className="systemMessage">
                    <p class="nextActivityMessage">
                        <button className="send-btn" name="nextSS" onClick={nextSS} value="">Next Activity: {nextSSname}</button><br/>
                        Knowledge Panel<br/>
                        <button className="send-btn" name="startOver" onClick={sendReInitMessage} value="">Start Over</button><br/>
                        <button className="send-btn" name="menu" onClick={menu} value="">Menu</button><br/>
                        Event message: {item['M'].message['S']}
                    </p>
                </div>
    
            )
        }

        // If no further subscenario exists within this scenario, offer to start another scenario or go back to activity menu
        if((nextSSname === null) && (nextSCname !== null)){
            return (

                <div className="systemMessage">
                    <p class="nextActivityMessage">
                        <button className="send-btn" name="nextSC" onClick={nextSC} value="">Next Scenario: {nextSCname}</button><br/>
                        Knowledge Panel<br/>
                        <button className="send-btn" name="startOver" onClick={sendReInitMessage} value="">Start Over</button><br/>
                        <button className="send-btn" name="menu" onClick={menu} value="">Menu</button><br/>
                        Event message: {item['M'].message['S']}
                    </p>
                </div>
    
            )
        }

    }


    // TODO: Needs to be fixed
    // Redirects to next subscenario within current scenario if available.
    function nextSS(){

        var nextSS = (parseInt(userSessionObject.currentSubScenario) + 1).toString()

        updateSessionObject('currentSubScenario', nextSS)

    }

    // TODO: Needs to be fixed
    // Redirects to next scenario if available. Starts at subscneario with id 0
    function nextSC(){

        var nextSC = (parseInt(userSessionObject.currentScenario) + 1).toString()

        // setCurrentSubScenario("0")
        // setCurrentScenario(nextSC)

        updateSessionObject("currentSubScenario", null)
        updateSessionObject("currentScenario", nextSC)
        updateSessionObject("currentSubScenario", "1")
    }

    // TODO: Needs to be fixed
    // Redirects back to activity choice page
    function menu(){
        
        // localStorage.removeItem("currentSubScenario")
        // localStorage.removeItem("currentScenario")

        // setCurrentScenario(null)
        // setCurrentSubScenario(null)

        return <Navigate to="/about"/>
        
    }



    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////


    // CurrentChat

    // Reloads on changes to userConv and ChatsPage component reload
    useEffect(() => {

        log.debug("CHATPROVIDER: useEffect triggered (initial load or reload)")

        if(activityData){

            console.log(userSessionObject)

            const a = userSessionObject.currentScenario !== null
            const b = userSessionObject.currentSubScenario !== null

            console.log("A&&B ", a, b, a&&b)

            if((a) && (b)){

                // TODO: This should not get executed when entries are NULL!!!
                // FIX FIX FIX??

                console.log(typeof(userSessionObject.currentScenario))


                log.debug("ActivityData: ", activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].bySS.L)

                const bySS = activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].bySS.L.filter(item => (item.M.ssID.N == userSessionObject.currentSubScenario))
                
                const taskDesc = bySS[0].M.byLevel.M.beginner.M.userTask.M.description.S
                const taskDet = bySS[0].M.byLevel.M.beginner.M.userTask.M.details.S
                const sendOff = bySS[0].M.byLevel.M.beginner.M.userTask.M.sendoff.S

                const taskStr = taskDesc + "\n" + "\n" + sendOff
                const taskDetailsList = taskDet.split(".")

                const taskImageLink = bySS[0].M.byLevel.M.beginner.M.imgLink.S

                const ssCount = activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].bySS.L.length
                const scCount = activityData.scenario_data.length

                const salesLogo = bySS[0].M.modelIconLink.S


                // Checks if there is another subscenario available in the sequence. Sets as null if false.
                if((parseInt(userSessionObject.currentSubScenario) + 1) < ssCount){

                    log.debug("AD userSessionObject.currentScenario at idx 0's bySS at parseInt(currentSubsScenario) + 1", activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].bySS.L.at(parseInt(userSessionObject.currentSubScenario) + 1))

                    var nextSub = parseInt(userSessionObject.currentSubScenario)+1
                    log.debug("nextSub is: ", nextSub)
                    
                    const nextSSname = activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].bySS.L.at(nextSub).M.ssNameZH.S

                    setNextSSname(nextSSname)

                    log.debug("More subscenarios exist in the sequence. Current subscenario is: ", bySS[0].M.ssNameEN.S, " Next subscenario is: ", nextSSname)

                } else {setNextSSname(null)}


                if((parseInt(userSessionObject.currentSubScenario) + 1) == ssCount){

                    log.debug("Last subscenario in the list")

                }


                // Checks if there is another scenario available in the sequence. Sets as null if false.
                if((parseInt(userSessionObject.currentScenario) + 1) < scCount){
                    const nextSCname = activityData.scenario_data.at(parseInt(userSessionObject.currentScenario) + 1).scZH.S
                    setNextSCname(nextSCname)

                    log.debug("More scenarios exist in the sequence. Current scenario is: ", activityData.scenario_data.filter(item => (item.scID.N == (parseInt(userSessionObject.currentScenario))))[0].sc.S, " Next scenario is: ", nextSCname)

                } else (setNextSCname(null))


                if((parseInt(userSessionObject.currentScenario) + 1) == scCount){
                    log.debug("Last scenario in the list")
                    
                }

                
                setTaskImage(taskImageLink)
                setCurrentTask(taskStr)
                setTaskDetails(taskDetailsList)

                setScenarioScreenName(activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].scZH.S)
                setScenarioScreenNameEN(activityData.scenario_data.filter(item => (item.scID.N == userSessionObject.currentScenario))[0].sc.S)
                setSubScenarioScreenName(bySS[0].M.ssNameZH.S)
                setSubScenarioScreenNameEN(bySS[0].M.ssNameEN.S)

                setSsCount(ssCount)
                setScCount(scCount)

                setSalesLogo(salesLogo)
                
            }

        }



        if(userConv){

            if(userSessionObject.currentScenario != null && userSessionObject.currentSubScenario != null){


                log.debug("userConv available")

                log.debug("UserConv is: ", userConv)

                const chatObject = userConv.Items.filter(item => ((item.scenarioID['N'] == userSessionObject.currentScenario) && (item.subscenarioID['N'] == userSessionObject.currentSubScenario)))[0]

                log.debug("ChatObject for selected activity is: ", chatObject)

                if (chatObject == null){
                    setChatMessages(null)
                    return
                }

                // Assigns attributes to messages depending on payload type
                const chatMessages = chatObject.messages.L.map(item => {

                    if(item['M'].messageType['S'] == "userMessage"){
                        return (
                            <div className="userMessage"><p>{item['M'].message['S']}</p><img src={`${userLogo}`} alt="User Avatar"/></div>
                        )

                    }

                    if(item['M'].messageType['S'] == "regular"){

                        return resolveHighlights(item)

                    }

                    if(item['M'].messageType['S'] == "langErr"){
                        return(<div className="modelMessage"><p class="langErrMessage">LANGUAGE ERROR: {item['M'].message['S']}</p></div>)
                    }


                    if(item['M'].messageType['S'] == "taskErr"){
                        return (
                            <div className="modelMessage"><p class="taskErrMessage">Let's Check the Task: {item['M'].message['S']}</p></div>
                        )

                    }
                    

                    if(item['M'].messageType['S'] == "grammarErr"){
                        return (
                            <div className="modelMessage"><p class="grammarErrMessage">Grammar Correction: {item['M'].message['S']}</p></div>
                        )
                    }


                    if(item['M'].messageType['S'] == "vocabErr"){
                        return (
                            <div className="modelMessage"><p class="vocabErrMessage">Vocabulary Correction: {item['M'].message['S']}</p></div>
                        )
                    }


                    if(item['M'].messageType['S'] == "taskComplete"){

                        return (
                            <div className="modelMessage"><p class="taskCompleteMessage">Task successfully completed! {item['M'].message['S']}</p></div>
                        )

                    }
                    

                    if(item['M'].messageType['S'] == "systemMessage"){

                        const lastStep = resolveLastStep(item)

                        return lastStep;
                        
                    }

                })


                setChatMessages(chatMessages)


            }


        }


    }, [userConv, activityData, userSessionObject, nextSSname, nextSCname])





    // Values to be exported via ChatProvider
    const chatValues = {

      currentTask,
      setCurrentTask,
      taskImageLink,
      setTaskImage,
      scenarioScreenName,
      setScenarioScreenName,
      scenarioScreenNameEN,
      setScenarioScreenNameEN,
      subScenarioScreenName,
      subScenarioScreenNameEN,
      setSubScenarioScreenNameEN,
      setSubScenarioScreenName,
      chatMessages,
      setChatMessages,
      taskDetails,
      setWebSocketReady,
      setSocket,
      sendMessage,
      sendEvent,
      sendInitMessage,
      sendReInitMessage,
      routeInboundEvent,
      webSocketReady,
      Event

    }
  
    return (
      <ChatContext.Provider value={chatValues}>{children}</ChatContext.Provider>
    )

}


export { ChatProvider, ChatContext }
