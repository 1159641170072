
import React, { createContext, useContext, useState, useEffect } from "react";

import { AuthContext } from "./AuthContext";


import log from 'loglevel'


if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }
const UserContext = createContext();



function UserProvider({children} ) {

  // Authenticated User's information
  const { user, session, isLoading, isSessionLoading } = useContext(AuthContext)

  const [userConv, setUserConv] = useState(null)

  const [activityData, setActivityData] = useState(null)

  const [serverMessage, setServerMessage] = useState("")


  const [userSessionObject, setUserSessionObject] = useState({
    currentScenario: null,
    currentSubScenario: null,
    isNewUser: true,
    uiTheme: "light"
  })



  const initializeSessionObject = async() => {

    const apiEndpoint = "https://6rdljv5p50.execute-api.us-west-1.amazonaws.com/Development/getSessionState"

    try{

      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: session.idToken.jwtToken,
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch session data");
      }

      const sessionData = await response.json();

      log.debug("initializeSessionObject Call resulted in: ", sessionData);


      // Read the session object
      log.debug("Reading Session Object: ", sessionData.items.filter(item => (item.settingType == "currentScenario"))[0]);


      // Sets userSessionObject attributes
      setUserSessionObject({
        currentScenario: sessionData.items.filter(item => (item.settingType == "currentScenario"))[0].settingValue  || null,
        currentSubScenario: sessionData.items.filter(item => (item.settingType == "currentSubScenario"))[0].settingValue || null,
        isNewUser: sessionData.items.filter(item => (item.settingType == "isNewUser"))[0].settingValue || true,
        uiTheme: sessionData.items.filter(item => (item.settingType == "ui-theme"))[0].settingValue || "light",
      });

      return true;

    } catch (err) {

      log.debug("Error while retrieving User Session Data: ", err)

      return null;
    }

  }





  const updateSessionObject = async(key, value) => {

    setUserSessionObject((prev) => ({... prev, [key]: value}))

    // Make required updates to db

    const apiEndpoint = (key == "currentScenario") ? ("https://6rdljv5p50.execute-api.us-west-1.amazonaws.com/Development/setSessionState?currentScenario=" + value) : (
      (key == "currentSubScenario") ? ("https://6rdljv5p50.execute-api.us-west-1.amazonaws.com/Development/setSessionState?currentSubScenario=" + value) : null
    )

    if (apiEndpoint == null) {
      log.debug("Update attributes could not be verified. Failed to update")
      return null
    }
    

    try{

      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: session.idToken.jwtToken,
        }
      });

      // Check validity of response
      // if (!response.ok) {
      //   throw new Error("Failed to fetch session data");
      // }


      return true;


    } catch (err) {

      log.debug("Error while retrieving Activity Data: ", err)

      return null;
    }



  }




  // const [currentSubScenario, setCurrentSubScenario] = useState(() => {
  //   // Try to load the state from local storage
  //   const localData = localStorage.getItem('currentSubScenario');
  //   return localData ? JSON.parse(localData) : undefined;
  // });






  const loadUserData = async () => {

    const apiEndpoint = `https://2ammaxyqla.execute-api.us-west-1.amazonaws.com/Dev/getuserdata`

    try{

      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          Authorization: session.idToken.jwtToken,
        }
      });


      const userConv = await response.json();
      log.debug("userData Call resulted in: ", userConv);

      // Initialize session
      const result = await initializeSessionObject();

      return userConv;


    } catch (err) {

      log.debug("Error while retrieving User Data: ", err)

      return null;
    }

    
  }


  // Pulls scenario data and usertasks. To be requested from API Gateway endpoint 
  // TODO: Returns a map with scenarios, subscenarios, usertasks
  const loadActivityData = async() => {

    const apiEndpoint = "https://in6dmv6p59.execute-api.us-west-1.amazonaws.com/Dev/getAppData"

    try{

      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          Authorization: session.idToken.jwtToken,
        }
      });


      // Expected maps with 'scenarios' and 'usertasks'
      const AppData = await response.json();

      log.debug("loadActivityData Call resulted in: ", AppData);

      return AppData;


    } catch (err) {

      log.debug("TOKEN: ", session.idToken.jwtToken)

      log.debug("Error while retrieving Activity Data: ", err)

      return null;
    }



  }



  



  // // // Sets currentScenario and currentSubScenario
  // useEffect(() => {

  //   // Persist state changes to local storage
  //   if(!isLoading){

  //     // localStorage.setItem('currentScenario', JSON.stringify(currentScenario));
  //     // localStorage.setItem('currentSubScenario', JSON.stringify(currentSubScenario));

  //   }
  
  // }, [userSessionObject]);





// Handles cases when new message received & component reload
useEffect(() => {

  // New message received
  if(!isSessionLoading && serverMessage != ""){


    log.debug("Loading user conversation data on new message")

    // Do not update conversation data on handshake message
    if (serverMessage.type === "handshakeAckMessage"){
      return
    }


    log.debug("New Server message received. Updating conversation data only!", "server message is: ", serverMessage)
    loadUserData()
    .then(userConversations => {


    if(userConversations) {
      setUserConv(userConversations);
      log.debug("userConv looks like:", userConversations)
    }


    }).catch(err => log.debug("UseEffect loadUserData failed", err));

  }

  // Session/page reload
  if (!isSessionLoading && serverMessage == "") {
    log.debug("Loading user conversation and activity data on component load / reload")

  loadActivityData()
  .then(AppData => {

    if(AppData) {
      setActivityData(AppData);

    }

  }).catch(err => log.debug("UseEffect loadAppData failed", err));


  loadUserData()
  .then(userConversations => {

    if(userConversations) {
      setUserConv(userConversations);
      log.debug("userConv looks like:", userConversations)
    }

  }).catch(err => log.debug("UseEffect loadUserData failed", err));

  }

}, [isSessionLoading, serverMessage])





    // Values to be exported via UserProvider
    const userValues = {
      user,
      userConv,
      activityData, 
      isSessionLoading,
      serverMessage,
      userSessionObject,
      updateSessionObject,
      setActivityData,
      loadUserData,
      setUserConv,
      loadActivityData,
      setServerMessage,
    }
  
    return (
      <UserContext.Provider value={userValues}>{children}</UserContext.Provider>
    )

}


export { UserProvider, UserContext }
