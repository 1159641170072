import React from "react";
import { Outlet } from "react-router-dom"

import { getCurrentUser, signOut } from '../../authentication/authHelper';

export default function ReviewPage() {


    const [user, setUser] = React.useState()

    React.useEffect(() => {

        const fetchUser = async () => {

        try {

            const user = await getCurrentUser()
            setUser(user)

        } catch (err) {
            console.error(err)
        }


        }

        fetchUser()

    }, [])


    
    return (

        <>

        <h1>Flashcards</h1>

        <h1>New Words Only</h1>

        <h1>Review</h1>
    
        <h1>**NEW!** Thematic Reviews - Choose a topic!</h1>
        
        </>
               
    )
    
}