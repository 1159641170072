import React, { useState } from "react"

import { signUp, confirmSignUp } from "../../authentication/authHelper"

import { Navigate } from "react-router-dom"


import "./css/style.css"

export default function SignUpPage() {


  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await signUp(username, email, password)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }



  if (success) {

    // Set Up API Gateway -> Lambda -> Dynamo via VPC Gateway Endpoint
    // Create an empty Dynamo Record with UID
    // Key: uid
    // values: 
    // Progress: Empty dict
    // 

    return (
      <div>
        <h2>SignUp successful!</h2>
        <p>Please check your email for the confirmation code. Enter it below: </p>
      </div>
    )
  }


  

  return (
    <div className="signup--section">
      <h2>Register a New Account</h2>
      <form className="signup--form" onSubmit={handleSubmit}>
        <input className="signup--form--input"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input className="signup--form--input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input className="signup--form--input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="signup--form--button" type="submit">Sign Up</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  )
}