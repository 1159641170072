import React, {useContext, useEffect, useRef} from "react";

import "./css/CurrentChat.css";


import { ChatContext } from "../../context/ChatContext";




export default function CurrentChat(){
    
    const {chatMessages} = useContext(ChatContext)

    const endOfChatRef = useRef(null);




    useEffect(() => {

        if (endOfChatRef.current) {
            endOfChatRef.current.scrollIntoView({ behavior: "smooth"});
        }

    }, [chatMessages])



    return(
        
        <div className="chat-current">

            {chatMessages}
            <div ref={endOfChatRef}></div>

        </div>
            
        
    )
    
}