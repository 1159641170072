
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Global styles
import "./style.css"

// Application-Layout Element
import Layout from "./components/Layout"; 

// Pages
import HomePage from "./pages/Home/Home";
import AboutPage from "./pages/About";
import MenuPage from "./pages/Scenarios/ScenarioGallery";
import AccountPage from "./pages/Account/AccountPage";
import ActivityPage from "./pages/Chat/ChatsPage";
import NotFoundPage from './pages/Error/NotFound';

// Authentication Pages
import SignUpPage from "./pages/SignUp/SignUp";
import ConfirmSignUpPage from "./pages/SignUp/ConfirmSignUp";
import LoginPage from "./pages/Login/Login";
import LoginWithAmazon from "./components/Login/LoginWithAmazon";


// Account-level components
import AccountLayout from "./components/Account/AccountLayout";
import ManageUser from "./pages/Account/ManageUser";
import KnowledgeBase from "./pages/Account/KnowledgeBase";

// RouteGuard wall
import RouteGuard from './components/RouteGuard';

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import { ChatProvider } from './context/ChatContext';
import ReviewPage from './pages/Review/ReviewPage';
import VocabPage from './pages/Vocabulary/VocabPage';


// Main Application Driver Function
export default function App() {

      return (

        <AuthProvider>

          <UserProvider>

          <ChatProvider>

            <BrowserRouter>
                    
                      <Routes>

                          <Route element={<Layout />}>
                        
                            <Route index element={<HomePage/>}/>
                            <Route path="/about" element={<AboutPage />}/>

                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/loginwithamzn" element={<LoginWithAmazon />} />
                            <Route path="/signup" element={<SignUpPage />} />
                            <Route path="/confirmsignup" element={<ConfirmSignUpPage />} />


                            <Route 
                              path="/practice" 
                              element={
                                <RouteGuard>
                                  <MenuPage/>
                                </RouteGuard>
                              }
                            />

                            <Route path="/vocabulary" element={<VocabPage />} />
                            <Route path="/review" element={<ReviewPage />} />

                          
                            <Route path="account" element={<RouteGuard><AccountLayout /></RouteGuard>}>
                              <Route index element={<AccountPage />}/>
                              <Route path="manage-user" element={<ManageUser />}/>
                              <Route path="knowledge-base" element={<KnowledgeBase />}/>
                            </Route>
                            


                            <Route 
                              path="/chats" 
                              element={
                                <RouteGuard>
                                  <ActivityPage />
                                </RouteGuard>
                              }
                            />

                            <Route path="*" element={<NotFoundPage />}/>
                          

                          </Route>


                          
                      </Routes>
              

            </BrowserRouter>
                    
          </ChatProvider>
          </UserProvider>


        </AuthProvider>
        
  
    )
}
