import React, { useEffect } from 'react';

function AmazonLoginPage() {


    useEffect(() => {
    // Load the Amazon Login SDK
    const amazonScript = document.createElement('script');
    amazonScript.type = 'text/javascript';
    amazonScript.async = true;
    amazonScript.id = 'amazon-login-sdk';
    amazonScript.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';


    // Initialize Amazon Login when the SDK is ready
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId('amzn1.application-oa2-client.9f08922e33ec436cbd6601fe22ec3780');
    };
    document.getElementById('amazon-root').appendChild(amazonScript);

    if (window.amazon) {
        console.log('Amazon SDK is ready');
      } else {
        console.error('Amazon SDK failed to load');
      }

    return () => {
      // Cleanup the script if the component unmounts
      if (document.getElementById('amazon-login-sdk')) {
        document.getElementById('amazon-login-sdk').remove();
      }
    };

}, []);


const handleAmazonLogin = () => {
    const options = {
      scope: 'profile',
      scope_data: {
        profile: { essential: false },
      },
    };

    window.amazon.Login.authorize(options, "http://localhost:3000/"); // Replace with your redirect URL
  };

  return (
    <div>
      <div id="amazon-root"></div>
      <h1>Login with Amazon</h1>
      <button id="LoginWithAmazon" onClick={handleAmazonLogin}>
        Login with Amazon
      </button>
    </div>
  );
}




export default AmazonLoginPage;
