import React from "react";

import "./css/ExistingChatsHeader.css";


import { useContext } from "react";
import { ChatContext } from "../../context/ChatContext";


export default function ExistingChatsHeader() {
    
    const {scenarioScreenName} = useContext(ChatContext)
    
    return (
    
        <div className="chat-list--header">
    
            <h1>{scenarioScreenName}</h1>
        
        </div>
    
    
    )
    
}