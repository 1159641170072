import React from "react"


export default function TopicTags(props) {

    const topics = props.item

    const tags = topics.map(item => {
        
        return (
                <div class="topicTag">
                    {item}
                </div>
            )
    })
    
    
    return (
    
        <div className="modelTopics">
            {tags}
        </div>

    )
    
}