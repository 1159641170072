import React from "react"




export default function ManageUser(){
    
    

    


 
     return (
    
        <section className="account--page">
            <h1>Hi! This is your account page - Management Console.</h1>
            
            <h1>If user is fully managed by cognito userpools.</h1>
            <h1>Change My password</h1>
            <h1>Change My email address</h1>
            <h1>Change My username</h1>
            <h1>Delete my account</h1>
            
        </section>
        

    )
    
    
}


