import React from "react"




export default function ManageUser(){
    
    
 
     return (
    
        <section className="account--page">
            <h1>Hi! This is your account page - Knowledge Base.</h1>
            <h2>Here you can view and export your vocabulary acquired with MyChinesePal</h2>

            <h2>Vocabulary Exposure: 64 New Words</h2>
            <h2>Other cool Stats 1: XXX</h2>
            <h2>Other cool Stats 2: XXX</h2>

            <h2>Export vocabulary</h2>
            <h3>Tick Words | Examples | xxxx</h3>


        </section>
        

    )
    
    
}


