import React, {useContext, useEffect, useState} from "react";



import { ChatContext } from "../../context/ChatContext";


import UserTaskText from "./UserTaskText";

// Provides activity description (Task / image / any intro details) and start button. 
// Start button sends an event message of type "activity init?" to ws, which loads the first model message into table. 
// Chatshell reloads page when message ready!

export default function LaunchActivity() {

    

    const {taskImage, currentTask, sendInitMessage} = useContext(ChatContext)



    return (
    
    
        <>  
        
        <div>
            <p>{currentTask}</p>
            <p>Activity Image inserted here</p>
            {/* <img src={`public/images/${taskImage}`} alt="Activity Image Here"/> */}


        </div>
        

        <p>Start this activity? (communicates w/ ws if connection open)</p>
        <button className="send-btn" onClick={sendInitMessage} name="field_name" value="">Start!</button>
        
        </>
    )
}