import React, {useState, useContext} from "react"

import "./css/TextInput.css"
import { ChatContext } from "../../context/ChatContext";




// Defines, fills and returns the new message input element

        


export default function TextInput(){
    

    const [currentMessage, setCurrentMessage] = useState('');

    const {sendMessage} = useContext(ChatContext)




    function handleInputChange(event) {

        const message = event.target.value

        setCurrentMessage(message);

    }
    

    function handleKeyDown(event) {
        if (event.key === "Enter"){
            handleSend(event)
        }
    }


    function handleSend(event) {

        event.preventDefault()

        // Do not send a message consisting of whitespace
        if(currentMessage.trim() === ""){
            return
        }

        sendMessage(currentMessage)

        setCurrentMessage("")

    }


    
    return(
        
        
        <div className="chat-inputbox">
            
            <input 
                type="text" 
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="请输入。。"
                value={currentMessage}
            />
            <button className="send-btn" onClick={handleSend} name="field_name" value="">发出</button>
        

        </div>


        

    )
    
}