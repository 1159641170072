import React, { useEffect } from "react"
import {Link} from "react-router-dom"



import SubScenarioGallery from "./SubScenarioGallery"


import Card from "../../components/Scenarios/Card/Card"
// import SubCard from "../../components/Scenarios/SubCard"

import "../../style.css"


import { UserContext } from "../../context/UserContext"

import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
} else {
    log.setLevel('debug'); // Enable debug-level logging
}

export default function MenuPage() {



    const {updateSessionObject, activityData, userSessionObject} = React.useContext(UserContext)


    useEffect(() => {

        updateSessionObject("currentScenario", null)
        updateSessionObject("currentSubScenario", null)

    }, [])


    // State local to this component. Initialized only when activityData is not null.
    const [currentCards, setCurrentCards] = React.useState(null)




    useEffect(() => {

        if(activityData){

            log.debug("SCENARIO OBJECTS: ", activityData.scenario_data)

            const scenarioCards = activityData.scenario_data.sort((a, b) => (a.scID.N > b.scID.N)).map(item => {

                log.debug("SCENARIO CARD object rendered: ", item)


                return (
        
                    <Card
                    
                    
                        key={item.scID.N}
                        id={item.scID.N}
                        item={item}
                        isCompleted={false}


                    />
        
        
                )
    
            })
    
            setCurrentCards(scenarioCards)
            

        }

        

    }, [activityData])







    return (
    

        <div className="scenarioGallery">

            <h1 className="activity--intro">Pick a scenario</h1>

            <section className="cards-list">
                {currentCards}
            </section> 

            {userSessionObject.currentScenario !== null &&
                
                <SubScenarioGallery id={userSessionObject.currentScenario}/>
                
            }

            <h1 className="activity--intro">Or choose one below</h1>


            <div className="feedbackForm">

                <p>Feedback or Feature Request?</p>

            </div>


        </div>
        

    )
    
}