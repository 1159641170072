// Maintains Authentication context for a user

import React, { createContext, useState, useEffect } from "react";
import * as auth from "../authentication/authHelper"

import log from 'loglevel';

if (process.env.NODE_ENV === 'production') {
    log.setLevel('silent'); // Suppress all logs
  } else {
    log.setLevel('debug'); // Enable debug-level logging
  }

// Auth Context only provides authentication status, not user data

const AuthContext = createContext();



function AuthProvider( {children} ) {


    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState(null)
    const [isSessionLoading, setIsSessionLoading] = useState(true)

    
    const getCurrentUser = async () => {
        try {
            const user = await auth.getCurrentUser()
            setUser(user)
        } catch (err) {
            // not logged in
            log.debug(err)
            setUser(null)
        }
    }



    const getSession = async () => {

      try{
          const currentSession = await auth.getSession()
          log.debug("Current session object: ", currentSession)
          setSession(currentSession)
        } catch (err) {
          log.debug("Error retrieving session object:", err)
        }

    }
  
    

    useEffect(() => {
        getCurrentUser()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false))     
          
      }, [])


      useEffect(() => {

        if (user){
          getSession()
          .then(() => setIsSessionLoading(false))
          .catch(() => setIsSessionLoading(false))  
        
        }
          
      }, [user])




      const signIn = async (username, password) => {
        await auth.signIn(username, password)
        await getCurrentUser()
      }
      const signOut = async () => {
        await auth.signOut()
        setUser(null)
      }

      const authValue = {
        user,
        isLoading,
        signIn,
        signOut,
        session,
        isSessionLoading
      }
    
      return (
        <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
      )

}


export { AuthProvider, AuthContext }
