import React, {useContext, useEffect, useState} from "react";

import "./css/ChatShell.css";


// Chat window components
import ExistingChats from "./ExistingChats";
import TextInput from "./TextInput";
import CurrentChat from "./CurrentChat";

import ChatHeader from "./ChatHeader";
import ExistingChatsHeader from "./ExistingChatsHeader";



import { ChatContext } from "../../context/ChatContext";


import UserTaskText from "./UserTaskText";


// Defines, fills and returns the chat window layout
// <div className="chat-container">


// Chat container consists of the following functional div elements:
// chat-list
// chat-current
// chat-knowledge
// chat-inputbox



export default function ChatShell() {
    
    


    // Uses ChatContext
    const {currentTask, nextSSname} = useContext(ChatContext)
    


    React.useEffect(() => {


        // if(serverEvent != null){

        //     log.debug(
        //         "Model Event of Type: ", serverEvent.type, " and message: ", serverEvent.payload, " processed by ChatShell!"
        //     )


        // }


    }, [nextSSname])



    
    return (

        <>
        
        
        <div className="taskIntro">
            <p> {currentTask}</p>
        </div>

        
        <div className="chat-container">

            <ExistingChats/>
            <CurrentChat/>
            <TextInput />
            <ChatHeader/>
            <ExistingChatsHeader/>
            <UserTaskText/>
            
            
        </div>

        

        </>
   
    
    )
    
}