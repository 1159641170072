import React, { useContext } from "react"


import TopicTags from "./TopicTags"



import { UserContext } from "../../../context/UserContext"


export default function Card(props){
    
    
    // const {setCurrentScenario} = useContext(UserContext)
    const {updateSessionObject} = useContext(UserContext)
 

    return (
        
        <div className="card" onClick= {() => {updateSessionObject("currentScenario", props.id)}}  >
        
        {/* <div className="card" onClick= {() => {getScenarioInDB()}}  > */}


            <div>
                <p className="card--name"> {props.item.scZH.S} </p>
            </div>
        

            <img src={`${props.item.imgLink.S}`} className="card--image" alt=""/>

                        
            <div>
                 <TopicTags 
                    item={props.item.topics.SS}
                />
            </div>
        
        </div>
    
    )
    





}