import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom"

import { getCurrentUser, signOut } from '../../authentication/authHelper';
 
import { UserContext } from "../../context/UserContext";

export default function AccountPage() {



    const [user, setUser] = React.useState()

    const { updateSessionObject } = useContext(UserContext)

    useEffect(() => {

        updateSessionObject("currentScenario", null)
        updateSessionObject("currentSubScenario", null)

    }, [])



    React.useEffect(() => {

        const fetchUser = async () => {

        try {

            const user = await getCurrentUser()
            setUser(user)

        } catch (err) {
            console.error(err)
        }


        }

        fetchUser()

    }, [])


    // const userData = ModelData.map(item => {
        
    //     return (
    //             <ModelCard
    //                 key={item.id}
    //                 // img={item.coverImg}
    //                 // rating={item.stats.rating}
    //                 // reviewCount={item.stats.reviewCount}
    //                 // location={item.location}
    //                 // title={item.title}
    //                 // price={item.price}
    //                 // openSpots={item.openSpots}
    //                 item={item}
                    
    //                 // or {...item} (spreads data attributes directly into the component)
    //             />
    //         )
    
    // })
    
    
    return (
    
        <section className="account--page">
            <h1>Hi {user && user.username}! This is your account page.</h1>

            
            {user &&
                <>
                    <h1>Here is Some information about your account:</h1>
                    <h1>Username: {user.username}</h1>
                    <h1>Your eMail: {user.email}</h1>
                    <h1>eMail Verified: {user.email_verified}</h1>
                    <h1>Unique UserID: {user.sub}</h1>    


                </>

            }


            <Outlet />
        </section>
        

    )
    
}