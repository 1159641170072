import React from "react"
import { NavLink, Outlet } from "react-router-dom"



export default function AccountLayout() {
    
    return(
    
        <div>
            <nav className="account--nav">
               <NavLink to="/account" end className={({isActive}) => isActive ? "link--current" : null}>Dashboard</NavLink>
               <NavLink to="/account/manage-user" className={({isActive}) => isActive ? "link--current" : null}>Manage</NavLink>
               <NavLink to="/account/knowledge-base" className={({isActive}) => isActive ? "link--current" : null}>Knowledge Base</NavLink>
            </nav>   
        
            <Outlet />
        </div>
    )
    
}